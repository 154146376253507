@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=DM+Sans:wght@400;700&display=swap');

/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#background-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Behind all other elements */
    background: linear-gradient(135deg, #1b1b1f, #101018); /* Subtle gradient */
    overflow: hidden;
}


body {
    background: radial-gradient(circle, #1c1c1c, #0e0e0e);
    font-family: 'DM Sans', sans-serif;
    color: #e2e2e2;
    overflow-x: hidden;
}

@font-face {
    font-family: 'Abadi';
    src: url('fonts/abadi-mt-std.ttf') format('truetype');
}

h1 {
    text-align: center;
    font-size: 3.8rem;
    color: #f2f2f2;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-family: 'Abadi';
    letter-spacing: 2px;
}

.small-text {
    text-align: center;
    font-size: 0.9rem;
    color: #cccccc;
    margin-top: -10px;
    margin-bottom: 20px;
    font-family: 'DM Sans', sans-serif;
}




/* Navbar Styling */
.navbar {
    display: flex;
    justify-content: center;
    gap: 2rem;
    background: linear-gradient(135deg, #14161a, #1e1e24);
    padding: 0.8rem 2rem;
    border-radius: 12px;
    max-width: 1200px; /* Same max-width as the container */
    margin: 20px auto; /* Center alignment */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}


/* Navbar Link Styling */
.navbar-link {
    color: #b8bfc9;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    border-radius: 6px;
    transition: color 0.3s ease, background-color 0.3s ease;
    font-family: 'Nunito', sans-serif;
}

/* Hover and Active Styles for Navbar Links */
.navbar-link:hover,
.navbar-link:focus,
.navbar-link.active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 600;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}



/* Mint Page Container Styling */
.mint-container {
    max-width: 1200px; /* Matches the width of the navigation bar */
    margin: 0 auto;
    padding: 60px 50px; /* Adjusted padding for better spacing */
    background-color: #2b2b2b;
    border-radius: 20px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #f1f1f1;
    font-family: 'DM Sans', sans-serif;
    border: 2px solid transparent;
    background-image: linear-gradient(#2b2b2b, #2b2b2b), radial-gradient(circle, #32d5f2, #1b85bd);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

/* Mint Button Styling */
.mint-button {
    padding: 16px 50px; /* Padding for button size */
    background: linear-gradient(135deg, #4286f4, #32d5f2); /* Gradient background */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    border-radius: 40px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    margin-top: 25px; /* Add space above */
    display: inline-block; /* Ensure it respects padding and spacing */
    text-align: center; /* Center text */
}

.mint-button:hover {
    background: linear-gradient(135deg, #2a6fd4, #1bc3e0); /* Change gradient on hover */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow */
    transform: scale(1.05); /* Slight zoom effect */
}

.mint-container h2.form-header {
    font-size: 1.8rem;
    color: #f1f1f1;
    margin-bottom: 1.5rem;
    font-weight: bold;
    border-bottom: 2px solid #32d5f2;
    padding-bottom: 10px;
}

/* Error Message Styling */
.mint-container .error-message {
    color: #ff4d4d;
    font-weight: 600;
    margin-top: 10px;
    font-size: 0.9rem;
}

.main-header, h1 {
    text-align: center;
    font-size: 3.8rem;
    color: #f2f2f2;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-family: 'Abadi';
    letter-spacing: 2px;
}

.mint-container .transaction-message {
    margin-top: 10px;
    color: #32d5f2; /* Blue color for Mint Token Page */
    font-weight: bold;
}

.mint-container .transaction-message a {
    color: #32d5f2; /* Blue link color */
    text-decoration: none;
}

.mint-container .transaction-message a:hover {
    color: #1b85bd; /* Slightly darker blue on hover */
}








.withdraw-fees-button {
    padding: 16px 50px; /* Padding for button size */
    background: linear-gradient(135deg, #4286f4, #32d5f2); /* Gradient background */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    border-radius: 40px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    margin-top: 10px; /* Add space above */
    margin-bottom: 15px;
    display: inline-block; /* Ensure it respects padding and spacing */
    text-align: center; /* Center text */
}

/* Add hover effects */
.withdraw-fees-button:hover {
    background: linear-gradient(135deg, #2a6fd4, #1bc3e0); /* Change gradient on hover */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow */
    transform: scale(1.05); /* Slight zoom effect */
}

/* Ensure button is not affected by other styles */
.withdraw-fees-button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 10px rgba(50, 213, 242, 0.8); /* Glow effect */
}

.check-contract-balance-button {
    padding: 16px 50px; /* Padding for button size */
    background: linear-gradient(135deg, #4286f4, #32d5f2); /* Gradient background */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    border-radius: 40px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    margin-top: 10px; /* Add space above */
    display: inline-block; /* Ensure it respects padding and spacing */
    text-align: center; /* Center text */
}

/* Add hover effects */
.check-contract-balance-button:hover {
    background: linear-gradient(135deg, #2a6fd4, #1bc3e0); /* Change gradient on hover */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow */
    transform: scale(1.05); /* Slight zoom effect */
}

/* Ensure button is not affected by other styles */
.check-contract-balance-button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 10px rgba(50, 213, 242, 0.8); /* Glow effect */
}




.create-token-button {
    padding: 16px 50px; /* Padding for button size */
    background: linear-gradient(135deg, #4286f4, #32d5f2); /* Gradient background */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    border-radius: 40px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    margin-top: 25px; /* Add space above */
    display: inline-block; /* Ensure it respects padding and spacing */
    text-align: center; /* Center text */
}

/* Add hover effects */
.create-token-button:hover {
    background: linear-gradient(135deg, #2a6fd4, #1bc3e0); /* Change gradient on hover */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow */
    transform: scale(1.05); /* Slight zoom effect */
}

/* Ensure button is not affected by other styles */
.create-token-button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 10px rgba(50, 213, 242, 0.8); /* Glow effect */
}

.create-token-link {
    color: #32d5f2; /* Matches your theme */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.create-token-link:hover {
    color: #8a2be2; /* Change color on hover */
    text-decoration: underline;
}









/* Token List Styling */
.my-tokens-page-token-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)); /* Two cards per row */
    gap: 24px;
    padding: 0;
    list-style: none;
    margin-top: 20px;
}

/* Individual Token Card Styling */
.my-tokens-page-token-card {
    background: linear-gradient(145deg, #222, #111);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 20px;
    color: #f5f5f5;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.9);
    transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Add hover effect for cards */
.my-tokens-page-token-card:hover {
    box-shadow: 0 15px 50px rgba(50, 213, 242, 0.7);
    transform: translateY(-10px);
    background: linear-gradient(145deg, #292929, #1a1a1a);
}

/* Card Header Styling */
.token-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 16px;
}

/* Token Name Styling */
.token-name {
    font-size: 2rem;
    font-weight: bold;
    color: #32d5f2;
    text-transform: uppercase;
    margin: 0;
}

/* My Token Details Section */
.my-token-details {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Uniform spacing between all rows */
    margin-bottom: 20px; /* Space below the details section */
}

/* My Token Details Row */
.my-token-details-row {
    display: flex;
    justify-content: space-between; /* Align label and value */
    align-items: center; /* Vertical alignment */
    font-size: 1.1rem;
}

.my-token-details-row strong {
    color: #aaa;
    font-weight: bold;
}

.my-token-details-row span {
    color: #f5f5f5;
    text-align: right; /* Align values to the right */
    font-size: 1.1rem;
}

/* Contract Address Styling */
.my-token-details-row a {
    color: #32d5f2; /* Blue color for the contract address */
    text-align: right; /* Align text to the right */
    font-size: 1.1rem;
    text-decoration: none;
    transition: color 0.3s ease, text-shadow 0.3s;
}

.my-token-details-row a:hover {
    color: #1bc3e0; /* Brighter blue on hover */
    text-decoration: none;
}

/* Status Styling */
.status {
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
}

.status.green {
    background: rgba(50, 205, 50, 0.2);
    color: #32cd32;
    box-shadow: inset 0 0 10px rgba(50, 205, 50, 0.5);
}

.status.red {
    background: rgba(255, 69, 0, 0.2);
    color: #ff4500;
    box-shadow: inset 0 0 10px rgba(255, 69, 0, 0.5);
}

/* Card Footer */
.token-card-footer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

/* Action Buttons Styling */
.token-action-button {
    flex: 1;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(135deg, #4286f4, #32d5f2);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.token-action-button:hover {
    background: linear-gradient(135deg, #1e90ff, #00bfff);
    box-shadow: 0 8px 16px rgba(50, 213, 242, 0.8);
    transform: scale(1.05);
}

.token-action-button:active {
    transform: translateY(2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}





















/* Filters Section */
.my-tokens-page-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; /* Four equal columns for balance */
    align-items: center; /* Vertically center-align all elements */
    gap: 20px; /* Uniform spacing between items */
    background: linear-gradient(135deg, #25272c, #1c1c22);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 30px;
    margin-top: 5px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
    font-family: 'Nunito', sans-serif;
}

/* Filter Group */
.filter-group {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between label and input */
    align-items: flex-start; /* Aligns label and input */
}

/* Filter Labels */
.filter-label {
    font-size: 0.85rem; /* Compact label font size */
    font-weight: 600;
    color: #e2e2e2;
    text-align: left;
}

/* Input Fields (Dropdowns and Text Inputs) */
.filter-select,
.filter-input {
    padding: 8px 12px;
    font-size: 0.9rem;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #292929;
    color: #ffffff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: center; /* Center-align text in inputs */
    width: 220px; /* Same width for all input fields */
    height: 36px; /* Consistent height */
}

/* Hover Effects */
.filter-select:hover,
.filter-input:hover {
    background-color: #35363d;
    box-shadow: 0 0 5px rgba(50, 213, 242, 0.3);
}

/* Checkbox Container */
.checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and label */
    cursor: pointer;
}

/* Checkbox */
.filter-checkbox {
    width: 18px;
    height: 18px;
    appearance: none;
    border: 2px solid #32d5f2;
    border-radius: 4px;
    background-color: #1e1e24;
    outline: none;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.filter-checkbox:checked {
    background-color: #32d5f2;
    border-color: #32d5f2;
}

.filter-checkbox:checked::after {
    content: "✓";
    color: white;
    font-size: 12px;
    font-weight: bold;
}

/* Checkbox Label */
.checkbox-label {
    font-size: 0.85rem;
    font-weight: 600;
    color: #e2e2e2;
    cursor: pointer;
}

/* Decimals and Sort by Group */
.decimals-and-sort {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between Sort by and Decimals */
    grid-column: span 1; /* Keep this grouped in one column */
}

/* Balance Filters Group */
.balance-filters {
    display: flex;
    flex-direction: column; /* Stacked layout */
    gap: 10px; /* Space between Min and Max fields */
}

.balance-filters .filter-input {
    text-align: center; /* Center-align the input values */
    width: 100%; /* Ensure consistency */
    height: 36px; /* Matches other input heights */
}

/* Even Spacing for Filter Items */
.my-tokens-page-filters > * {
    margin: 0 auto; /* Ensure even spacing and centering */
}

/* Remove arrows from number inputs */
.filter-input[type="number"]::-webkit-inner-spin-button,
.filter-input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filter-input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.filters-toggle-button {
    padding: 6px 14px; /* Smaller padding for a compact button */
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 0.85rem; /* Smaller font size */
    background: linear-gradient(135deg, #2c2c2c, #1e1e24); /* Neutral dark gradient */
    color: #e2e2e2; /* Light gray text to match the scheme */
    border: 1px solid #444; /* Subtle border */
    border-radius: 8px; /* Slightly rounded corners */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Subtle shadow */
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px; /* Slight spacing for readability */
}

.filters-toggle-button:hover {
    background: linear-gradient(135deg, #3b3b3b, #2a2a2a); /* Slight hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6); /* Enhance shadow on hover */
    color: #ffffff; /* Brighter text on hover */
}


























/* Account Info Styles */
.account-info {
    font-size: 1rem;
    text-align: center;
    margin-top: 15px;
}

.account-address {
    color: #4286f4;
    font-weight: 600;
}

/* Centered Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px;
    background-color: #2b2b2b;
    border-radius: 20px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #f1f1f1;
    font-family: 'DM Sans', sans-serif;
    border: 2px solid transparent;
    background-image: linear-gradient(#2b2b2b, #2b2b2b), radial-gradient(circle, #32d5f2, #1b85bd);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}


.price-section.top-center {
    text-align: center;
    width: 100%;
    margin-top: 0;
    padding: 10px 0;
    font-size: 18px;
    color: #333; /* Ensure this is visible against your background */
}


/* Token Form and Details */
.token-form,
.token-details {
    background-color: #3a3a3a;
    border-radius: 12px;
    padding: 60px;
    margin: 30px auto;
    max-width: 900px;
    color: #ffffff;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.6);
}

.token-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Form Row */
.form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

/* Form Group */
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
}


.form-group textarea,
.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #4b4f54;
    border-radius: 6px;
    background-color: #1e1e24;
    color: #e0e0e0;
}

/* Ensure the height of the input in the Logo URL box matches the height of the Description box */
.form-group.logo-url-box input {
    height: 100%;
}

/* Container for the Description and Logo URL to keep them side by side */
.form-row.full-width {
    display: flex;
    gap: 20px; /* Adds space between the Description and Logo URL sections */
    align-items: stretch; /* Ensures both elements align and stretch evenly */
}

/* Description box styling */
.form-group.description-box {
    flex: 1; /* Takes half of the container width */
}

/* Logo URL box styling */
.form-group.logo-url-box {
    flex: 1; /* Takes half of the container width */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.form-label {
    font-size: 1.3rem;
    color: #ffffff;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 5px;
}

/* Input Fields */
input[type="text"],
input[type="number"] {
    width: 100%;
    padding: 18px;
    border: none;
    border-radius: 12px;
    background-color: #444;
    color: #ffffff;
    font-size: 1.2rem;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
    font-family: 'DM Sans', sans-serif;
}

/* Description Textarea */
.token-form textarea {
    width: 100%;
    padding: 18px;
    border: none;
    border-radius: 12px;
    background-color: #444;
    color: #ffffff;
    font-size: 1.2rem;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
    font-family: 'DM Sans', sans-serif;
    resize: none;
    text-align: left;
}

input::placeholder,
textarea::placeholder {
    color: #b8b8b8;
    font-size: 1.1rem;
}

input:focus,
textarea:focus {
    outline: none;
    background-color: #555;
    box-shadow: inset 0px 8px 16px rgba(0, 0, 0, 0.6);
}






/* Styling for the upload button container to align with description */
.logo-url-box {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the button vertically */
    flex: 1;
}

/* Description box styling */
.form-group.description-box,
.form-group.logo-url-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upload-button-as-input {
    width: 100%;
    height: 100%;
    padding: 0 10px; /* Adjust padding to keep the text centered */
    font-size: 1rem;
    color: #b8b8b8;
    background-color: #444;
    border: 1px solid #4b4f54;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    transition: background-color 0.3s;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.4);
}

.upload-button-as-input:hover {
    background-color: #555;
}






/* Image Preview Styling */
.image-preview {
    margin-top: 15px;
    text-align: center;
}

.image-preview img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

/* Token Preview Styling */
.token-preview {
    margin-top: 30px;
    padding: 30px 50px;
    background-color: #2d2d2d;
    border-radius: 15px;
    color: #f1f1f1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5), 0 0 15px #32d5f2;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid transparent;
    background-image: linear-gradient(#2d2d2d, #2d2d2d), radial-gradient(circle, #32d5f2, #1b85bd);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.token-preview h3 {
    font-size: 1.8rem;
    color: #ffffff;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.preview-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #444;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin: 0 auto 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.preview-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.placeholder-logo {
    color: #b8b8b8;
    font-size: 1.2rem;
    font-style: italic;
}

.token-preview p {
    font-size: 1.3rem;
    color: #d3d3d3;
    margin: 10px 0;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.token-preview .token-attribute {
    font-weight: 700;
    color: #32d5f2;
    text-transform: uppercase;
    margin-right: 5px;
}

.token-preview .attribute-text {
    font-weight: 400;
    color: #f1f1f1;
    margin-left: 5px;
}



.form-header-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.form-header {
    font-size: 1.8rem;
    color: #f1f1f1;
    font-weight: bold;
    border-bottom: 2px solid #32d5f2;
    padding-bottom: 10px; 
}

.fee-text {
    font-size: 1rem;
    color: #cccccc;
    margin-left: 10px;
    font-weight: normal;
    text-align: right; 
    white-space: nowrap; 
}




/* Token Decimals Container */
.token-decimals-container {
    width: auto;
    max-width: 48%; /* Match other form elements in the row */
}

/* Decimals input container */
.token-decimals-input {
    display: flex;
    align-items: center;
    border: 1px solid #4b4f54;
    border-radius: 12px;
    background-color: #444;
    overflow: hidden;
    width: 100%;
    height: 50px; /* Match other input heights */
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.4); /* Subtle inner shadow */
}

/* Button Styling */
.decrement-button,
.increment-button {
    width: 40px;
    height: 100%;
    background: linear-gradient(135deg, #4286f4, #32d5f2);
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    border-radius: 12px 0 0 12px; /* Rounded corners on the left */
}

/* Adjust button height */
.increment-button {
    border-radius: 0 12px 12px 0; /* Rounded corners on the right */
}

/* Hover effect for buttons */
.decrement-button:hover,
.increment-button:hover {
    background-color: #4b4f54;
}

/* Active effect for buttons */
.decrement-button:active,
.increment-button:active {
    background-color: #5a656b;
    transform: scale(0.95);
}

/* Display for the decimal value */
.decimals-display {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #e0e0e0;
    background-color: #444;
    border: none;
}














/* Token Created Styling */
.token-created {
    margin-top: 30px;
    padding: 40px;
    background-color: #2d2d2d;
    border-radius: 15px;
    color: #f1f1f1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5), 0 0 15px #32d5f2;
    font-family: 'DM Sans', sans-serif;
    max-width: 800px;
    margin: 30px auto;
    border: 2px solid #32d5f2;
    text-align: left;
}

.token-created h3 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    border-bottom: 2px solid #32d5f2;
    padding-bottom: 10px;
}

/* Wrapper for each attribute-value pair */
.token-created .attribute-wrapper {
    margin: 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid #444;
}

/* Styling for attribute labels, placed above values */
.token-created .token-attribute {
    font-size: 1rem;
    font-weight: 600;
    color: #32d5f2;
    text-transform: uppercase;
    margin-bottom: 4px;
}

/* Centering the button */
.token-created .button-wrapper {
    display: flex;
    justify-content: center;
}

/* Button Styling */
.token-created button {
    margin-top: 20px;
    padding: 16px 50px;
    background: linear-gradient(135deg, #4286f4, #32d5f2);
    color: #fff;
    border: none;
    border-radius: 40px;
    font-size: 1.3rem;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    transition: all 0.3s ease;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.token-created button:hover {
    background: linear-gradient(135deg, #2a6fd4, #1bc3e0);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5);
}



.my-tokens-page-no-tokens-message {
    margin-top: 10px; /* Add spacing above the text */
    font-size: 1rem;
    color: #e2e2e2;
    text-align: center;
    line-height: 1.5;
}

.my-tokens-page-no-tokens-message a {
    color: #32d5f2; /* Link color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.my-tokens-page-no-tokens-message a:hover {
    color: #1bc3e0; /* Change color on hover */
    text-decoration: none;
}


.my-tokens-page-loading-message {
    margin-top: 10px; /* Add spacing above the text */
    font-size: 1rem;
    color: #e2e2e2;
    text-align: center;
    line-height: 1.5;
}









/* Connect Wallet Button */
.connect-wallet1 {
    position: relative; /* For pseudo-element positioning */
    background: linear-gradient(135deg, #1e1e24, #25272c); /* Base dark gradient */
    color: #e2e2e2;
    border: 2px solid transparent; /* Transparent border for animation visibility */
    border-radius: 16px;
    padding: 10px 18px;
    margin-top: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    outline: none;
    overflow: hidden; /* Prevent pseudo-element overflow */
    z-index: 1; /* Ensure it’s above other content */
}

/* Dynamic Border Animation with Brighter Hues */
.connect-wallet1::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 20px; /* Matches button’s radius */
    background: linear-gradient(
        135deg,
        rgba(73, 144, 226, 1),
        rgba(50, 213, 242, 1),
        rgba(133, 77, 255, 1),
        rgba(73, 144, 226, 1)
    );
    background-size: 200% 200%; /* Adjusted scaling for smoother animation */
    z-index: -1; /* Place behind the button */
    animation: gradient-wave 3s infinite linear; /* Smooth looping animation */
    opacity: 0.9; /* Bright glow */
}

/* Gradient Wave Animation */
@keyframes gradient-wave {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Inner Glow Pulse Animation */
.connect-wallet1::after {
    content: "";
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    border-radius: 12px;
    background: radial-gradient(circle, rgba(50, 213, 242, 0.4), transparent 70%);
    z-index: -1; /* Ensure it stays behind the text */
    animation: inner-pulse 2.5s infinite alternate; /* Subtle inner glow */
}

/* Inner Pulse Keyframes */
@keyframes inner-pulse {
    0% {
        transform: scale(1);
        opacity: 0.25;
    }
    100% {
        transform: scale(1.1);
        opacity: 0.15;
    }
}

/* Hover Effect for Connect Wallet Button */
.connect-wallet1:hover {
    background: linear-gradient(135deg, #2a2a2a, #35363d); /* Slight darkening effect */
    color: #ffffff;
    box-shadow: 0 8px 20px rgba(50, 213, 242, 0.9); /* Stronger blue glow */
    border-color: rgba(50, 213, 242, 0.9); /* Matches brighter animation colors */
}

/* Focus State for Accessibility */
.connect-wallet1:focus {
    outline: 3px solid rgba(73, 144, 226, 0.9); /* Bright blue outline */
    outline-offset: 4px;
}




/* Wallet Info Container */
.wallet-info {
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    font-family: 'Nunito', sans-serif;
    border-radius: 16px;
    transition: box-shadow 0.3s ease, top 0.3s ease, right 0.3s ease; /* Smooth position adjustments */
}

/* Connected Wallet Info */
.connected-wallet {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 6px;
    background: linear-gradient(135deg, #1e1e24, #25272c);
    padding: 10px 15px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth hover effects */
}

.connected-wallet:hover {
    box-shadow: 0 8px 15px rgba(50, 213, 242, 0.5);
    background-color: #1b1b25;
}


/* Wallet Icon Styling */
.wallet-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #32d5f2;
    flex-shrink: 0;
}

/* Wallet Address */
.wallet-address {
    color: #32d5f2;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    cursor: pointer;
    position: relative;
}

/* Tooltip for Full Wallet Address */
.wallet-address:hover::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 0;
    background: #333;
    color: #fff;
    font-size: 0.85rem;
    padding: 6px 12px;
    border-radius: 8px;
    white-space: nowrap;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* Tooltip Arrow for Wallet Address */
.wallet-address:hover::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    z-index: 9;
}

/* AGC Balance */
.wallet-balance {
    background: linear-gradient(135deg, #32d5f2, #1b85bd); /* Subtle gradient for standout effect */
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700; /* Slightly bolder for emphasis */
    padding: 6px 10px; /* Padding to create badge-like appearance */
    border-radius: 12px; /* Rounded badge style */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Balance Hover Effect */
.wallet-balance:hover {
    transform: scale(1.1); /* Slight zoom effect */
    box-shadow: 0 4px 10px rgba(50, 213, 242, 0.5);
}























/* Main container for toggle switches */
.checkbox-container {
    display: flex;
    gap: 20px;
    margin-top: 15px;
    justify-content: center;
}

/* Styling for each toggle group */
.toggle-group {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: #cfd1d3;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    position: relative;
}

/* Hide the actual checkbox input */
.toggle-input {
    display: none;
}

/* Styling the toggle slider */
.toggle-slider {
    position: relative;
    width: 50px;
    height: 24px;
    background: linear-gradient(135deg, #2a2e35, #3a3f47);
    border-radius: 12px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

/* Circle inside the toggle for slider effect */
.toggle-slider::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

/* Toggle "on" state */
.toggle-input:checked + .toggle-slider {
    background: linear-gradient(135deg, #32d5f2, #1e90ff);
    box-shadow: 0 0 10px rgba(50, 213, 242, 0.6);
}

/* Move circle to the right when checked */
.toggle-input:checked + .toggle-slider::before {
    transform: translateX(26px);
    background-color: #ffffff;
}

/* Hover effect on toggle group */
.toggle-group:hover .toggle-slider {
    box-shadow: 0 0 12px rgba(50, 213, 242, 0.5); /* Slight glow on hover */
}

/* Label hover effect for interactivity */
.toggle-group:hover {
    color: #ffffff;
}

/* Focus effect on checkbox for accessibility */
.toggle-input:focus + .toggle-slider {
    outline: none;
    box-shadow: 0 0 5px 2px #32d5f2;
}

/* Refined info icon styling */
.info-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    font-size: 0.75rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007acc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.15s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Smooth scaling effect on hover */
.info-icon:hover {
    background-color: #005f99;
    transform: scale(1.1);
}

/* Tooltip styling for readability and positioning */
.info-icon .tooltip-text {
    visibility: hidden;
    width: 260px; /* Set a fixed width to make it wider */
    background-color: rgba(30, 30, 30, 0.95);
    color: #ffffff;
    text-align: left; /* Align text to the left for easier reading */
    padding: 12px 15px; /* Increase padding for readability */
    border-radius: 8px;
    font-size: 0.9rem;
    position: absolute;
    bottom: 140%; /* Positioned above the icon with more space */
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    line-height: 1.5;
}

/* Tooltip arrow styling for a polished look */
.info-icon .tooltip-text::before {
    content: "";
    position: absolute;
    top: 100%; /* Arrow points downward */
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: rgba(30, 30, 30, 0.95) transparent transparent transparent;
}

/* Show tooltip on hover */
.info-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-8px); /* Moves tooltip slightly upward */
}


.total-fees {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #b0b3b8; /* Subtle grey color */
    text-align: center;
    font-weight: 500;
}









/* Burn Token Page */
.burn-token-page {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #2b2b2b;
    border-radius: 12px;
    color: #f1f1f1;
    font-family: 'DM Sans', sans-serif; /* Or your preferred font */
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
}

.burn-token-page input {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    font-size: 1rem;
    border: 1px solid #444;
    border-radius: 8px;
    background-color: #333;
    color: #fff;
    transition: border-color 0.3s;
}

.burn-token-page input:focus {
    outline: none;
    border-color: #e63946; /* Highlight border when focused */
}

.burn-token-page button {
    padding: 15px 30px;
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    border: none;
    border-radius: 8px;
    background-color: #e63946;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.burn-token-page button:hover {
    background-color: #d62828;
    transform: translateY(-2px);
}

.burn-token-page button:active {
    background-color: #c21818;
    transform: translateY(0);
}

.burn-token-page .status {
    margin-top: 20px;
    font-size: 1rem;
    color: #f4a261;
}

.burn-container {
    max-width: 1200px; /* Matches the width of the navigation bar */
    margin: 0 auto;
    padding: 60px 50px; /* Adjusted padding for better spacing */
    background-color: #2b2b2b;
    border-radius: 20px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #f1f1f1;
    font-family: 'DM Sans', sans-serif;
    border: 2px solid transparent;
    background-image: linear-gradient(#2b2b2b, #2b2b2b), radial-gradient(circle, #32d5f2, #1b85bd);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.burn-button {
    padding: 16px 50px; /* Padding for button size */
    background: linear-gradient(135deg, #ff4d4d, #ff7f7f); /* Gradient background */
    color: #fff; /* Text color */
    border: none; /* Remove border */
    border-radius: 40px; /* Rounded button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.3rem; /* Font size */
    font-family: 'Nunito', sans-serif; /* Font */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1.2px; /* Spacing between letters */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    margin-top: 25px; /* Add space above */
    display: inline-block; /* Ensure it respects padding and spacing */
    text-align: center; /* Center text */
}

.burn-button:hover {
    background: linear-gradient(135deg, #e60000, #ff4d4d); /* Change gradient on hover */
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow */
    transform: scale(1.05); /* Slight zoom effect */
}

.burn-container h2.form-header {
    font-size: 1.8rem;
    color: #f1f1f1;
    margin-bottom: 1.5rem;
    font-weight: bold;
    border-bottom: 2px solid #32d5f2;
    padding-bottom: 10px;
}

/* Select Token Dropdown */
.token-select {
    width: 100%;
    padding: 18px;
    font-size: 1rem;
    border: 1px solid #444;
    border-radius: 12px;
    background-color: #333;
    color: #f1f1f1;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    font-family: 'DM Sans', sans-serif;
    appearance: none; /* Remove native dropdown styling */
}

/* Add custom arrow for dropdown */
.token-select::after {
    content: '▼'; /* Downward arrow */
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    color: #f1f1f1;
    pointer-events: none;
}

/* Hover Effect */
.token-select:hover {
    background-color: #444;
    border-color: #555;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.2);
}

/* Focus Effect */
.token-select:focus {
    outline: none;
    border-color: #32d5f2; /* Accent color */
    background-color: #292929;
    box-shadow: 0px 0px 8px rgba(50, 213, 242, 0.5);
}

/* Option Styling */
.token-select option {
    background-color: #2b2b2b; /* Dropdown option background */
    color: #f1f1f1; /* Dropdown option text color */
    font-family: 'DM Sans', sans-serif;
    padding: 10px; /* Add spacing inside options */
    font-size: 1rem;
}

.burn-container .transaction-message {
    margin-top: 10px;
    color: #e60000; /* Red color for Burn Token Page */
    font-weight: bold;
}

.burn-container .transaction-message a {
    color: #e60000; /* Red link color */
    text-decoration: none;
}

.burn-container .transaction-message a:hover {
    color: #ff6347; /* Slightly lighter red on hover */
}




















.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1; 
    transition: opacity 0.3s ease;
}

/* Add this to make it disappear when needed */
.loading-overlay.hide {
    opacity: 0;
    visibility: hidden;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007acc;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-overlay p {
    margin-top: 20px;
    color: #fff;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}

.token-created .attribute-wrapper .copy-button {
    padding: 3px 10px; /* Slightly wider for better click area */
    font-size: 0.85rem; /* Slightly larger for better readability */
    color: #ffffff; /* Changed to white for better contrast */
    background-color: rgba(50, 213, 242, 0.1); /* Subtle background for contrast */
    border: 1px solid #32d5f2;
    border-radius: 20px; /* More rounded for a modern look */
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 8px; /* Small adjustment for spacing */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
    font-weight: 600; /* Bold to make text stand out */
}

.token-created .attribute-wrapper .copy-button:hover {
    background-color: #32d5f2;
    color: #ffffff;
    border: 1px solid transparent; /* Clean transition when hovered */
    box-shadow: 0px 4px 10px rgba(50, 213, 242, 0.3); /* Enhance shadow when hovered */
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3); /* Subtle shadow for better text contrast */
}





/* For screens below 1700px */
@media (max-width: 1850px) {
    .navbar {
        flex-wrap: wrap;
        padding: 1rem 2rem;
    }

    .wallet-info {
        position: static; /* Remove absolute positioning */
        margin-top: 1rem; /* Add spacing below navbar */
        display: flex;
        justify-content: center; /* Center wallet info */
        gap: 10px;
    }
}

/* For smaller screens (below 768px) */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 1rem;
        max-width: 90%;
    }

    .navbar-link {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
        margin-bottom: 0.5rem;
    }

    .container {
        padding: 20px;
        max-width: 100%;
    }

    .token-form,
    .token-details {
        padding: 20px;
    }

    .form-row {
        flex-direction: column;
        gap: 15px;
    }

    h1 {
        font-size: 2.5rem;
    }

    .small-text {
        font-size: 0.8rem;
    }

    .token-preview {
        padding: 20px;
    }

    .preview-logo {
        height: 80px;
        width: 80px;
    }

    .token-preview h3 {
        font-size: 1.5rem;
    }

    .token-preview p {
        font-size: 0.9rem;
    }

    .preview-description {
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .token-created {
        padding: 20px;
    }

    .token-created h3 {
        font-size: 1.4rem;
    }

    .token-created .token-attribute {
        font-size: 0.9rem;
    }

    .my-tokens-page-filters {
        flex-direction: column;
        gap: 10px;
        padding: 15px;
        text-align: center;
    }

    .my-tokens-page-token-list {
        grid-template-columns: 1fr; /* Single column for smaller screens */
    }

    .my-tokens-page-token-card {
        padding: 15px;
    }

    .my-tokens-page-token-name {
        font-size: 1.5rem;
    }

    .my-tokens-page-label {
        font-size: 0.85rem;
    }

    .my-tokens-page-sort-select,
    .filter-select,
    .filter-input {
        font-size: 0.85rem;
        padding: 6px 10px;
    }

    .filters-toggle-button {
        font-size: 0.85rem;
        padding: 6px 12px;
    }

    .connect-wallet1 {
        font-size: 1rem;
        padding: 12px 30px;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
    }

    .wallet-info {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 10px;
        font-size: 0.9rem;
        max-width: 100%;
    }

    .wallet-address {
        font-size: 0.9rem;
        word-wrap: break-word;
        text-align: left;
        max-width: 100%;
    }

    .wallet-balance {
        font-size: 0.9rem;
        padding: 6px 12px;
    }

    .connected-wallet {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 10px;
    }

    .wallet-icon {
        width: 20px;
        height: 20px;
    }
}
