/* Backdrop Styling */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
}

/* Modal Container */
.modal-container {
    background: linear-gradient(145deg, #1e1e1e, #2b2b2b);
    border-radius: 20px;
    width: 700px;
    max-width: 90%; /* Responsive width */
    padding: 40px;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.9);
    animation: slideUp 0.4s ease;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box; /* Ensure padding is included in width */
}

/* Header Styling */
.modal-header {
    font-size: 1.8rem;
    margin: 0;
    text-align: center;
    color: #32d5f2;
    margin-bottom: 20px;
}

/* Body Styling */
.modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add space between fields */
    width: 100%; /* Ensure full width */
}

/* Form Group */
.modal-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between label and input */
    width: 100%; /* Ensure inputs stretch across the modal */
}

/* Labels */
.modal-label {
    font-size: 1rem;
    font-weight: 500;
    color: #b0b0b0;
}

/* Input and Select Fields */
.modal-input {
    width: 100%; /* Ensure inputs take full width */
    padding: 14px 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    background: #2d2d2d;
    color: #ffffff;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box; /* Ensure consistent sizing */
}

.modal-input:focus {
    outline: none;
    border-color: #32d5f2;
    box-shadow: 0px 0px 8px rgba(50, 213, 242, 0.8);
}

/* Footer Styling */
.modal-footer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}

/* Buttons */
.modal-button {
    padding: 12px 25px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    text-align: center;
    min-width: 130px;
}

/* Primary Button */
.modal-button.primary {
    background: linear-gradient(135deg, #32d5f2, #1bc3e0);
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(50, 213, 242, 0.4);
}

.modal-button.primary:hover {
    background: linear-gradient(135deg, #1bc3e0, #32d5f2);
    transform: translateY(-3px);
    box-shadow: 0px 6px 15px rgba(50, 213, 242, 0.6);
}

.modal-button.primary:disabled {
    background: #777;
    color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}

/* Secondary Button */
.modal-button.secondary {
    background: linear-gradient(135deg, #444, #333);
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.modal-button.secondary:hover {
    background: linear-gradient(135deg, #555, #444);
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(50, 50, 50, 0.5);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1; 
    transition: opacity 0.3s ease;
}

/* Add this to make it disappear when needed */
.loading-overlay.hide {
    opacity: 0;
    visibility: hidden;
}

.loading-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007acc;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-overlay p {
    margin-top: 20px;
    color: #fff;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}
