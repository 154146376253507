.analytics-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .analytics-modal {
    background: #222;
    color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    background: none;
    border: none;
    color: #f5f5f5;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .modal-header {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .analytics-content {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .analytics-content ul {
    list-style: none;
    padding: 0;
  }
  
  .analytics-content ul li {
    margin-bottom: 5px;
  }
  